import { camelCaseKeys } from 'src/utils/camelcase';
import http from 'src/services/http';

export const apiLogin = async ({ email, password }: { email: string; password: string }) => {
    return http
        .post('auth/login', {
            Email: email,
            Password: password,
        })
        .then((res) => camelCaseKeys(res));
};

export const apiLoginSocial = async ({ socialToken, socialType }: { socialToken: string; socialType: number }) => {
    // socialToken 0: facebook, 1: google, 2: apple.
    return http
        .post('auth/register-social', {
            SocialType: socialType,
            SocialAccessToken: socialToken,
            Scopes: 'email,id',
            Source: 'web',
            ReferralCode: '',
            Language: 'en',
        })
        .then((res) => camelCaseKeys(res));
};
