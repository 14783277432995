import { Action } from 'redux';
import { INft, TabEnum } from '../Marketplace/Marketplace.types';

export enum AccountTypeAction {
    SET_BALANCE_TOKEN = 'ACCOUNT/SET_BALANCE_TOKEN',
    SET_BALANCE_NFT = 'ACCOUNT/SET_BALANCE_NFT',
    LOADING = 'ACCOUNT/LOADING',
    SET_ACTIVE_TAB = 'ACCOUNT/SET_ACTIVE_TAB',
}

export interface IBalanceToken {
    balance: string;
    tokenId: string;
    network: string;
    symbol: string;
    decimals: number;
}
export interface INftSpending {
    nftID: number;
    tokenId: string;
}

export interface INftInfo {
    id: number;
    idNft: number;
    userID: number;
    nft: INft;
    nftId: number;
    tokenID: string;
    network: string;
    status: number;
    remainDurability: string;
    earnedAmount: string;
    remainEnergy: string;
    refillTime: number;
}
export interface IBalanceNft {
    type: string;
    tokenId: string;
    items: INftInfo[];
}

export interface IAccountState {
    tokens: IBalanceToken[];
    nfts: IBalanceNft[];
    loading: boolean;
    tabActive: TabEnum;
}

//----------------------------------------------
// Payload Definition here!
//----------------------------------------------

export interface AccountTokenPayload {
    tokens: IBalanceToken[];
}

export interface AccountNftPayload {
    nfts: IBalanceNft[];
}

export interface AccountLoadingPayload {
    loading: boolean;
}

export interface AccountSetActiveTabPayload {
    tabActive: TabEnum;
}
//----------------------------------------------
// Payload Definition here!
//----------------------------------------------
export interface AccountTokenAction extends Action {
    type: AccountTypeAction.SET_BALANCE_TOKEN;
    payload: AccountTokenPayload;
}

export interface AccountNftAction extends Action {
    type: AccountTypeAction.SET_BALANCE_NFT;
    payload: AccountNftPayload;
}

export interface AccountLoadingAction extends Action {
    type: AccountTypeAction.LOADING;
    payload: AccountLoadingPayload;
}

export interface AccountSetActiveTabAction extends Action {
    type: AccountTypeAction.SET_ACTIVE_TAB;
    payload: AccountSetActiveTabPayload;
}

export type AccountActions = AccountTokenAction | AccountLoadingAction | AccountNftAction | AccountSetActiveTabAction;
