import React, { useEffect, useMemo } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';
import { MainRoute } from 'src/modules';
import { ThemeProvider, ThemedGlobalStyle } from 'src/theme';
import Header from 'src/components/Header';
import Modal from 'src/components/Modal';
import Footer from 'src/components/Footer';
import Loading from 'src/components/Loading';
import { ENVS, isMainnet } from 'src/configs/Configs.env';
import AOS from 'aos';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import withApp from './App.enhance';
import './reset.scss';
import 'antd/dist/antd.css';

import 'aos/dist/aos.css';

const history = createBrowserHistory();

const App: React.FunctionComponent = () => {
    useEffect(() => {
        AOS.init({
            duration: 2000,
        });
    }, []);
    return (
        <GoogleOAuthProvider clientId={ENVS.REACT_APP_GOOGLE_CLIENT_ID}>
            <ThemeProvider>
                <ThemedGlobalStyle />
                <Router history={history}>
                    <Header />
                    <MainRoute />
                    {/* <Footer /> */}
                    <Modal />
                    <Loading />
                </Router>
            </ThemeProvider>
        </GoogleOAuthProvider>
    );
};

export default withApp(React.memo(App));
