import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { Signin } from 'src/modules/Auth';
import { actionToggleModal } from '../components/Modal/Modal.actions';

const withLogin = (WrappedComponent: FunctionComponent & any) => (props: any) => {
    const dispatch = useDispatch();
    const openLoginModal = () => {
        dispatch(
            actionToggleModal({
                visible: true,
                onClose: () => {},
                data: <Signin />,
            }),
        );
    };
    return (
        <WrappedComponent
            {...{
                ...props,
                openLoginModal,
            }}
        />
    );
};

export default withLogin;
