import { Reducer } from 'redux';
import _ from 'lodash';
import { IMarketPlaceState, MarketPlaceActions, MarketPlaceActionType, TabEnum, TypeEnum } from './Marketplace.types';

const initialState: IMarketPlaceState = {
    isFetching: false,
    nfts: [],
    tabActive: TabEnum.SNEAKERS,
    type: TypeEnum.SNEAKERS,
    quality: null,
    sneakerType: null,
    gemType: null,
    level: {
        start: 0,
        end: 30,
    },
    levelPrev: {
        start: 0,
        end: 30,
    },
    shoeMint: {
        start: 0,
        end: 7,
    },
    shoeMintPrev: {
        start: 0,
        end: 7,
    },
    network: null,
    filter: {
        page: 1,
        limit: 10,
        sortPrice: 'esc',
    },
    total: 0,
};

const reducer: Reducer<IMarketPlaceState, MarketPlaceActions> = (
    state = initialState,
    action: MarketPlaceActions,
): IMarketPlaceState => {
    switch (action.type) {
        case MarketPlaceActionType.FETCHING_NFTS: {
            const { isFetching } = action.payload;
            return {
                ...state,
                isFetching,
            };
        }
        case MarketPlaceActionType.SET_NFTS: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case MarketPlaceActionType.SET_MORE_NFTS: {
            const { nfts } = action.payload;
            return {
                ...state,
                nfts: [...state.nfts, ...nfts],
            };
        }
        case MarketPlaceActionType.SET_SELECT_TAB: {
            const { tabActive } = action.payload;
            return {
                ...state,
                tabActive: tabActive as TabEnum,
                filter: { ...initialState.filter },
            };
        }
        case MarketPlaceActionType.SET_FILTER_TYPE: {
            const { type } = action.payload;
            return {
                ...state,
                type,
                filter: { ...initialState.filter },
            };
        }
        case MarketPlaceActionType.SET_FILTER_SORT_PRICE: {
            const { sortPrice } = action.payload;
            return {
                ...state,
                filter: { ...state.filter, sortPrice },
            };
        }
        case MarketPlaceActionType.SET_CURRENT_PAGE: {
            const { page } = action.payload;
            return {
                ...state,
                filter: { ...state.filter, page },
            };
        }
        case MarketPlaceActionType.SET_FILTER_SNEAKER_TYPE: {
            const { sneakerType } = action.payload;
            return {
                ...state,
                sneakerType,
                filter: { ...initialState.filter },
            };
        }
        case MarketPlaceActionType.SET_FILTER_QUALITY: {
            const { quality } = action.payload;
            return {
                ...state,
                quality,
                filter: { ...initialState.filter },
            };
        }
        case MarketPlaceActionType.SET_FILTER_LEVEL: {
            const { level } = action.payload;
            return {
                ...state,
                level: { ...level },
                filter: { ...initialState.filter },
            };
        }
        case MarketPlaceActionType.SET_FILTER_GEM_TYPE: {
            const { gemType } = action.payload;
            return {
                ...state,
                gemType,
                filter: { ...initialState.filter },
            };
        }
        case MarketPlaceActionType.SET_FILTER_LEVEL_PREV: {
            const { levelPrev } = action.payload;
            return {
                ...state,
                levelPrev: { ...levelPrev },
            };
        }
        case MarketPlaceActionType.SET_FILTER_SHOE_MINT: {
            const { shoeMint } = action.payload;
            return {
                ...state,
                shoeMint: { ...shoeMint },
                filter: { ...initialState.filter },
            };
        }
        case MarketPlaceActionType.SET_FILTER_SHOE_MINT_PREV: {
            const { shoeMintPrev } = action.payload;
            return {
                ...state,
                shoeMintPrev: { ...shoeMintPrev },
            };
        }
        case MarketPlaceActionType.CLEAR_FILER: {
            const cloneState = { ...state, nfts: [] };
            if (_.isEqual(initialState, cloneState)) {
                return state;
            }
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
