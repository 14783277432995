import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { actionToggleModal } from 'src/components/Modal/Modal.actions';
import { actionSetSignin, actionSignin, actionSigninSocial, actionSigninSocialSuccess } from './Auth.actions';
import AuthSuccess from './Auth.success';
import { SocialTypeEnum } from './Auth.types';
import { Signin } from './index';

const withAuth = (WrappedComponent: FunctionComponent) => (props: any) => {
    const dispatch = useDispatch();

    const openLoginModal = () => {
        dispatch(actionToggleModal({ visible: true, data: <Signin /> }));
    };
    const onSignin = async () => {
        try {
            await dispatch(actionSignin());
            dispatch(
                actionToggleModal({
                    autoClose: true,
                    visible: true,
                    data: <AuthSuccess title="Login Success" desc="You are in." />,
                }),
            );
        } catch (e: any) {
            dispatch(actionSetSignin({ error: e.message }));
        }
    };
    const onSigninSocial = async ({ type, token }: { type: SocialTypeEnum; token: string }) => {
        try {
            await dispatch(
                actionSigninSocialSuccess({
                    type,
                    token,
                }),
            );
            await dispatch(actionSigninSocial());
            dispatch(
                actionToggleModal({
                    autoClose: true,
                    visible: true,
                    data: <AuthSuccess title="Login Success" desc="You are in." />,
                }),
            );
        } catch (e: any) {
            dispatch(actionSetSignin({ error: e.message }));
        }
    };
    return (
        <WrappedComponent
            {...{
                ...props,
                openLoginModal,
                onSigninSocial,
                onSignin,
            }}
        />
    );
};
export default withAuth;
