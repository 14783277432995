import { Reducer } from 'redux';
import { TabEnum } from '../Marketplace/Marketplace.types';
import { AccountActions, AccountTypeAction, IAccountState } from './Account.types';

const initialState: IAccountState = {
    loading: false,
    nfts: [],
    tokens: [],
    tabActive: TabEnum.SNEAKERS,
};

const reducer: Reducer<IAccountState, AccountActions> = (
    state = initialState,
    action: AccountActions,
): IAccountState => {
    switch (action.type) {
        case AccountTypeAction.LOADING: {
            const { loading } = action.payload;
            return {
                ...state,
                loading,
            };
        }
        case AccountTypeAction.SET_BALANCE_TOKEN:
            return {
                ...state,
                tokens: [...action.payload.tokens],
            };
        case AccountTypeAction.SET_BALANCE_NFT:
            return {
                ...state,
                nfts: [...action.payload.nfts],
            };

        case AccountTypeAction.SET_ACTIVE_TAB: {
            const { tabActive } = action.payload;
            return {
                ...state,
                tabActive,
            };
        }

        default:
            return state;
    }
};

export default reducer;
