import React from 'react';
import { Drawer, Image } from 'antd';
import { ReactComponent as IcClose } from 'src/assets/svg/ic_close_drawer.svg';
import { ReactComponent as IcInventory } from 'src/assets/svg/common/inventory.svg';
import { ReactComponent as IcSingleArrow } from 'src/assets/svg/common/single-arrow.svg';
import { isMobile } from 'react-device-detect';
import PersonImg from 'src/assets/svg/common/person.png';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelector } from 'src/modules/Auth/Auth.selector';
import { IProfileState } from 'src/modules/Auth/Auth.types';
import { nftsSelector, tokensSelector } from 'src/modules/Account/Account.selector';
import { LogoutOutlined } from '@ant-design/icons';
import { format } from 'src/utils';
import { actionLogout } from 'src/modules/Auth/Auth.actions';
import { useHistory } from 'react-router-dom';
import Wrapper from './DrawerInfo.styled';

function DrawerInfo({ onClose, visible }: { onClose: () => void; visible: boolean }) {
    const profile: IProfileState | undefined = useSelector(profileSelector);
    const tokens = useSelector(tokensSelector);
    const nfts = useSelector(nftsSelector);
    const history = useHistory();
    const dispatch = useDispatch();
    const renderName = () => {
        if (profile?.firstName || profile?.lastName) return `${profile.firstName} ${profile.lastName}`;
        return profile?.username || profile?.email;
    };
    return (
        <Drawer
            placement="right"
            width={isMobile ? '80%' : '320px'}
            onClose={onClose}
            visible={visible}
            className="drawer-info"
            drawerStyle={{ backgroundColor: '#141416' }}
            closeIcon={<IcClose style={{ marginTop: 8 }} width={20} height={20} />}
        >
            <Wrapper>
                <div className="header-drawer">
                    <div className="left">
                        <Image preview={false} src={PersonImg} />
                    </div>
                    <div className="right fs-small fw-bold">{renderName()}</div>
                </div>
                <div className="spending-account">
                    <div className="header fw-bold fs-medium">Spending Tokens</div>
                    <div className="box-spending-tokens">
                        {tokens.map((token, idx) => (
                            <div
                                className={`spending-token ${idx === tokens.length - 1 ? 'none-border' : ''}`}
                                key={idx.toString()}
                            >
                                <div className="token">
                                    <Image
                                        src={`https://cdn.fitopia.io/assets/svg/${token.symbol.toLowerCase()}.svg`}
                                        preview={false}
                                    />
                                    <span className="fw-bold fs-regular">{token.symbol}</span>
                                </div>
                                <div className="balance fw-bold fs-regular">
                                    {format.amountV2({
                                        value: Number(token.balance),
                                        decimals: token.decimals,
                                        fixed: 3,
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="spending-account">
                    <div className="header fw-bold fs-medium">Spending Nfts</div>
                    <div className="box-spending-tokens">
                        {nfts.map((nft, idx) => (
                            <div
                                className={`spending-token ${idx === nfts.length - 1 ? 'none-border' : ''}`}
                                key={idx.toString()}
                            >
                                <div className="token">
                                    <Image
                                        src={`https://cdn.fitopia.io/assets/svg/${nft.type.toLowerCase()}.svg`}
                                        preview={false}
                                    />
                                    <span className="fw-bold fs-regular">Sneaker</span>
                                </div>
                                <div className="balance fw-bold fs-regular">{nft.items.length}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className="go-inventory"
                    onClick={() => {
                        history.push('/profile/inventory');
                    }}
                >
                    <div className="left">
                        <IcInventory /> <span className="fs-regular fw-bold">Inventory</span>
                    </div>
                    <div className="right">
                        <IcSingleArrow />
                    </div>
                </div>
                <div
                    className="go-inventory"
                    onClick={() => {
                        onClose();
                        dispatch(actionLogout());
                    }}
                >
                    <div className="left">
                        <LogoutOutlined /> <span className="fs-regular fw-bold">Logout</span>
                    </div>
                    <div className="right">
                        <IcSingleArrow />
                    </div>
                </div>
            </Wrapper>
        </Drawer>
    );
}
export default DrawerInfo;
