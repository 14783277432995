import { Action } from 'redux';

export enum AuthActionType {
    SIGNIN = 'AUTH/SIGNIN',
    SIGNIN_SUCCESS = 'AUTH/SIGNIN_SUCCESS',
    SIGNIN_SOCIAL_SUCCESS = 'AUTH/SIGNIN_SOCIAL_SUCCESS',
    LOGOUT = 'AUTH/LOGOUT',
    FREE = 'AUTH/FREE',
    LOADING = 'AUTH/LOADING',
    SET_PROFILE = 'AUTH/SET_PROFILE',
}

export enum SocialTypeEnum {
    FACEBOOK = 0,
    GOOGLE = 1,
    APPLE = 0,
}

export interface ISigninState {
    email?: string;
    password?: string;
    error?: string;
}

export interface IUserAuth {
    accessToken: string;
    email: string;
}
export interface IProfileState {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    isActive: boolean;
    isVerifiedEmail: boolean;
}
export interface IUserState {
    auth: IUserAuth;
    profile?: IProfileState;
}

export interface IAuthState {
    signin: ISigninState;
    userInfo: IUserState;
    loading: boolean;
    socialSinin?: {
        token: string;
        type: SocialTypeEnum;
    };
}
//----------------------------------------------
// Payload Definition here!
//----------------------------------------------

export interface AuthSigninPayload {
    email?: string;
    password?: string;
    error?: string;
}
export interface AuthSigninSuccessPayload {
    email: string;
    accessToken: string;
}

export interface AuthSigninSocialPayload {
    token: string;
    type: SocialTypeEnum;
}

export interface AuthLoadingPayload {
    loading: boolean;
}

export interface AuthSetProfilePayload {
    profile: IProfileState;
}
//----------------------------------------------
// Action Definition here!
//----------------------------------------------

export interface AuthSigninAction extends Action {
    type: AuthActionType.SIGNIN;
    payload: AuthSigninPayload;
}

export interface AuthSetProfileAction extends Action {
    type: AuthActionType.SET_PROFILE;
    payload: AuthSetProfilePayload;
}

export interface AuthSigninSuccessAction extends Action {
    type: AuthActionType.SIGNIN_SUCCESS;
    payload: AuthSigninSuccessPayload;
}

export interface AuthLogoutAction extends Action {
    type: AuthActionType.LOGOUT;
}

export interface AuthFreeAction extends Action {
    type: AuthActionType.FREE;
}

export interface AuthLoadingAction extends Action {
    type: AuthActionType.LOADING;
    payload: AuthLoadingPayload;
}

export interface AuthSigninSocialAction extends Action {
    type: AuthActionType.SIGNIN_SOCIAL_SUCCESS;
    payload: AuthSigninSocialPayload;
}

export type AuthActions =
    | AuthSigninAction
    | AuthSigninSuccessAction
    | AuthLogoutAction
    | AuthFreeAction
    | AuthLoadingAction
    | AuthLoadingAction
    | AuthSetProfileAction
    | AuthSigninSocialAction;
