import { createSelector } from 'reselect';
import { IRootState } from 'src/redux/interface';

export const accountSelector = createSelector(
    (state: IRootState) => state.account,
    (account) => account,
);
export const isAccountLoadingSelector = createSelector(accountSelector, (account) => account.loading);

export const tokensSelector = createSelector(accountSelector, (account) => account.tokens);
export const mainTokenSelector = createSelector(accountSelector, (account) => {
    return account.tokens.find((token) => token.symbol.toLowerCase() === 'bnb');
});
export const nftsSelector = createSelector(accountSelector, (account) => account.nfts);
export const nftsFlatMapSelector = createSelector(accountSelector, ({ nfts }) => nfts.flatMap((e) => e.items));
export const nftsActiveTabSelector = createSelector(
    accountSelector,
    (account) => account.nfts.find((nft) => nft.type === account.tabActive)?.items || [],
);

export const tabActiveSelector = createSelector(accountSelector, (account) => account.tabActive);
