import { Button, Menu, Row, Drawer, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { MailOutlined, MenuOutlined, SettingOutlined } from '@ant-design/icons';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as IcClose } from 'src/assets/svg/ic_close_drawer.svg';
import { ReactComponent as Logo } from 'src/assets/svg/marketplace/logo.svg';
import PersonImg from 'src/assets/svg/common/person.png';
import withLogin from 'src/hoc/login.enhance';
import { isLoginSelector } from 'src/modules/Auth/Auth.selector';
import { HeaderFrame } from './Header.styled';
import MenuMobile from './components/MenuMobile';
import { menuItemDesktop } from './HardData';
import DrawerInfo from './components/DrawerInfo';

const { SubMenu } = Menu;

interface IHeaderProps {
    openLoginModal: () => void;
}
function Header({ openLoginModal }: IHeaderProps) {
    const [width, setWidth] = useState(window.innerWidth);
    const [visible, setVisible] = useState(false);
    const breakpoint = 620;
    const history = useHistory();
    // const { chainId } = useSelector((state: any) => state.wallet);
    const isLogin = useSelector(isLoginSelector);
    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    useEffect(() => {
        if (!isLogin) {
            openLoginModal();
        }
    }, [isLogin]);
    const openMenu = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const MenuDesktop = () => {
        return (
            <div className="custom-header">
                <div
                    className="logo"
                    onClick={() => {
                        history.push('/marketplace');
                    }}
                >
                    <Logo />
                </div>
                <div className="account" onClick={isLogin ? openMenu : openLoginModal}>
                    <Image preview={false} src={PersonImg} />
                </div>
            </div>
        );
    };
    return (
        <HeaderFrame>
            <div className="custom-header">
                {width > breakpoint ? (
                    <div className="menu-desktop">{MenuDesktop()}</div>
                ) : (
                    <div className="menu-mobile">
                        <NavLink className="logo-mobile" to="/">
                            <Logo />
                        </NavLink>
                        <Row className="connect-mobile">
                            <Button
                                className="btn-round"
                                type="primary"
                                shape="round"
                                size="large"
                                onClick={isLogin ? openMenu : openLoginModal}
                            >
                                <Image preview={false} src={PersonImg} />
                            </Button>
                        </Row>
                    </div>
                )}
            </div>
            <DrawerInfo visible={visible} onClose={onClose} />
            {/* <Drawer
                placement="right"
                width="100%"
                closable
                visible={visible}
                key="right"
                onClose={onClose}
                drawerStyle={{ backgroundColor: '#141416' }}
                closeIcon={<IcClose style={{ marginTop: 8 }} width={20} height={20} />}
            >
                <div>
                    <MenuMobile onClose={onClose} />
                </div>
            </Drawer> */}
        </HeaderFrame>
    );
}

export default withLogin(Header);
