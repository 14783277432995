let decimalSeparator = '.';
let groupSeparator = ',';

export function getDecimalSeparator() {
    return decimalSeparator;
}

export function getGroupSeparator() {
    return groupSeparator;
}

export default {
    getDecimalSeparator,
    getGroupSeparator,
};
