import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { Reducer } from 'redux';
import { AuthActions, AuthActionType, IAuthState } from './Auth.types';

const initialState: IAuthState = {
    signin: {
        email: undefined,
        password: undefined,
    },
    userInfo: {
        auth: {
            accessToken: '',
            email: '',
        },
        profile: undefined,
    },
    socialSinin: undefined,
    loading: false,
};

const authReducer: Reducer<IAuthState, AuthActions> = (state = initialState, action: AuthActions): IAuthState => {
    switch (action.type) {
        case AuthActionType.SIGNIN: {
            return {
                ...state,
                signin: {
                    ...state.signin,
                    ...action.payload,
                },
            };
        }
        case AuthActionType.SIGNIN_SUCCESS: {
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    auth: {
                        ...action.payload,
                    },
                },
            };
        }
        case AuthActionType.LOGOUT: {
            return {
                ...state,
                userInfo: {
                    ...initialState.userInfo,
                },
            };
        }
        case AuthActionType.SIGNIN_SOCIAL_SUCCESS: {
            const { type, token } = action.payload;
            return {
                ...state,
                socialSinin: {
                    ...state.socialSinin,
                    type,
                    token,
                },
            };
        }
        case AuthActionType.SET_PROFILE: {
            const { profile } = action.payload;
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    profile: { ...profile },
                },
            };
        }
        case AuthActionType.FREE: {
            return {
                ...initialState,
            };
        }
        case AuthActionType.LOADING: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};

const persistConfig = {
    key: 'authReducer',
    storage,
    whitelist: ['userInfo'],
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, authReducer as any);
