import styled from 'styled-components';

const GradientText = styled.p`
    background: -webkit-linear-gradient(180deg, rgba(33, 101, 235, 1), rgba(151, 243, 142, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &::selection {
        -webkit-text-fill-color: white;
    }
`;

export default GradientText;
