import React, { memo, useEffect } from 'react';
import { Form } from 'antd';
import BTNPrimary from 'src/components/Button/Button.primary';
import { TextInput } from 'src/components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from 'src/modules/Auth/Auth.styled';
import validator from 'src/components/ReduxForm/validator';
import GradientText from 'src/components/GradiendText';
import { ReactComponent as GoogleSvg } from 'src/assets/svg/google.svg';
import { ReactComponent as FacebookSvg } from 'src/assets/svg/facebook.svg';
import { useGoogleLogin } from '@react-oauth/google';
// import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { ENVS } from 'src/configs/Configs.env';
import withAuth from './Auth.enhance';
import { loadingSelector, signinSelector } from './Auth.selector';
import { actionSetSignin } from './Auth.actions';
import { SocialTypeEnum } from './Auth.types';

interface ISigninProps {
    onSignin: () => void;
    onSigninSocial: ({ token, type }: { token: string; type: SocialTypeEnum }) => void;
}

const Signin = ({ onSignin, onSigninSocial }: ISigninProps & any) => {
    const dispatch = useDispatch();
    const { email, password, error } = useSelector(signinSelector);
    const loading = useSelector(loadingSelector);
    const [form] = Form.useForm();
    const formError = React.useMemo(() => {
        const _email = validator.email({ value: email });
        const _pass = validator.password({ value: password || '' });
        return {
            email: _email,
            pass: _pass,
            disabled: !!_email || !!_pass || loading,
        };
    }, [email, password, loading]);
    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            onSigninSocial({ type: SocialTypeEnum.GOOGLE, token: tokenResponse.access_token });
        },
    });
    const responseFacebook = (e: any) => {
        onSigninSocial({ type: SocialTypeEnum.FACEBOOK, token: e.accessToken });
    };
    return (
        <Wrapper>
            <Form className="form" form={form} name="vertical">
                <GradientText className="fw-bold disable-pointer title header-title">Sign in</GradientText>
                <TextInput
                    value={email}
                    onUserInput={(text) => {
                        dispatch(actionSetSignin({ email: text }));
                    }}
                    fontSize="12"
                    placeholder="Email address"
                    className="text-input"
                    label="EMAIL"
                    type="email"
                    error={formError.email}
                />
                <TextInput
                    value={password}
                    onUserInput={(text) => {
                        dispatch(actionSetSignin({ password: text }));
                    }}
                    fontSize="12"
                    placeholder="Password"
                    className="text-input"
                    label="PASSWORD"
                    type="password"
                    error={formError.pass}
                />
                {!!error && <p className="fs-small error">{error}</p>}
                <BTNPrimary
                    loading={loading}
                    disabled={formError.disabled}
                    className="button btn-signin"
                    title="Signin"
                    onClick={onSignin}
                />
            </Form>
            <div className="or-social fs-regular fw-medium text-center">OR</div>
            <div className="social-login">
                <div className="google-login" onClick={() => login()}>
                    <GoogleSvg /> <span className="fs-regular fw-medium">Google</span>
                </div>
                <div className="facebook-login">
                    <FacebookSvg />
                    <FacebookLogin
                        cssClass="fs-regular fw-medium"
                        appId={ENVS.REACT_APP_FACEBOOK_APP_ID}
                        // autoLoad={true}
                        fields="accessToken"
                        textButton="Facebook"
                        callback={responseFacebook}
                    />
                </div>
            </div>
        </Wrapper>
    );
};

export default withAuth(Signin);
