import { camelCaseKeys } from 'src/utils/camelcase';
import http from 'src/services/http';

export const apiGetProfile = async () => {
    return http
        .get('auth/profile', {
            headers: { Authorization: `Bearer ${window.token}` },
        })
        .then((res) => camelCaseKeys(res));
};

export const apiGetBalanceToken = async () => {
    return http
        .get('account-balance/token-info?filter[network]=bsc&filter[type.id]=1', {
            headers: { Authorization: `Bearer ${window.token}` },
        })
        .then((res) => camelCaseKeys(res));
};

export const apiGetBalanceNft = async () => {
    return http
        .get('account-balance/nft-info?filter[network]=bsc&filter[type.id]=1', {
            headers: { Authorization: `Bearer ${window.token}` },
        })
        .then((res) => camelCaseKeys(res));
};

export const apiGetTokenSupport = async ({ network = 'bsc' }: { network?: string }) => {
    return http.get(`account-balance/tokens-support?filter[network]=${network}`).then((res) => camelCaseKeys(res));
};

export const apiGetNftSupport = async ({ network = 'bsc' }: { network?: string }) => {
    return http.get(`nft-types?filter[network]=${network}`).then((res) => camelCaseKeys(res));
};
