import { Action } from 'redux';

export enum MarketPlaceActionType {
    FETCHING_NFTS = 'MARKETPLACE/FETCHING_NFTS',
    SET_NFTS = 'MARKETPLACE/SET_NFTS',
    SET_MORE_NFTS = 'MARKETPLACE/SET_MORE_NFTS',
    SET_SELECT_TAB = 'MARKETPLACE/SET_SELECT_TAB',
    SET_FILTER_TYPE = 'MARKETPLACE/SET_FILTER_TYPE',
    SET_FILTER_GEM_TYPE = 'MARKETPLACE/SET_FILTER_GEM_TYPE',
    SET_FILTER_QUALITY = 'MARKETPLACE/SET_FILTER_QUALITY',
    SET_FILTER_SNEAKER_TYPE = 'MARKETPLACE/SET_FILTER_SNEAKER_TYPE',
    SET_FILTER_LEVEL = 'MARKETPLACE/SET_FILTER_LEVEL',
    SET_FILTER_LEVEL_PREV = 'MARKETPLACE/SET_FILTER_LEVEL_PREV',
    SET_FILTER_SHOE_MINT = 'MARKETPLACE/SET_FILTER_SHOE_MINT',
    SET_FILTER_SHOE_MINT_PREV = 'MARKETPLACE/SET_FILTER_SHOE_MINT_PREV',
    SET_FILTER_NETWORK = 'MARKETPLACE/SET_FILTER_NETWORK',
    SET_FILTER_SORT_PRICE = 'MARKETPLACE/SET_FILTER_SORT_PRICE',
    SET_CURRENT_PAGE = 'MARKETPLACE/SET_CURRENT_PAGE',
    CLEAR_FILER = 'MARKETPLACE/CLEAR_FILER',
}

export enum TabEnum {
    SNEAKERS = 'Sneakers',
    GEMS = 'Gems',
    BADGES = 'Badges',
}

export enum TypeEnum {
    SNEAKERS = 'Sneakers',
    SHOES_BOXES = 'ShoesBoxes',
}

export enum QualityEnum {
    COMMON = 'Common',
    UNCOMMON = 'Uncommon',
    RARE = 'Rare',
    EPIC = 'Epic',
    LEGENDARY = 'Legendary',
}

export enum SneakerTypeEnum {
    WALKER = 'Walker',
    JOGGER = 'Jogger',
    RUNNER = 'Runner',
    TRAINER = 'Trainer',
}

export enum GemTypeEnum {
    EFFICIENCY = 'Efficiency',
    LUCK = 'Luck',
    COMFORT = 'Comfort',
    RESILIENCE_ICON = 'Resilience',
}

export enum NetworkEnum {
    BSC = 'bsc',
    SOL = 'Sol',
}

export enum SortPriceEnum {
    ESC = 'esc',
    DESC = 'desc',
}

export interface IFilterMarketPlace {
    page: number;
    limit: number;
    sortPrice: string;
}
export interface IMarketPlaceState {
    isFetching: boolean;
    nfts: INft[];
    tabActive: TabEnum;
    type: TypeEnum | null;
    quality: QualityEnum | null;
    gemType: GemTypeEnum | null;
    sneakerType: SneakerTypeEnum | null;
    level: {
        start: number;
        end: number;
    };
    levelPrev: {
        start: number;
        end: number;
    };
    shoeMint: {
        start: number;
        end: number;
    };
    shoeMintPrev: {
        start: number;
        end: number;
    };
    network: NetworkEnum | null;
    filter: IFilterMarketPlace;
    total: number;
}

export interface INft {
    id: number;
    userId?: number;
    nftId?: number;
    name?: string;
    prices?: number;
    image?: string;
    thumb?: string;
    type?: string;
    typeId?: number;
    quality?: string;
    sneakerType?: string;
    level?: number;
    shoeMint?: number;
    durability?: number;
    earnAmount?: string;
    energy?: string;
    isFavoured?: boolean;
    speed?: string;
    effect?: string;
    gemType?: string;
    gemTypeId?: number;
    network?: string;
    attribute?: IAttribute[];
    remainDurability?: string;
    earnedAmount?: string;
    remainEnergy?: string;
    refillTime?: number;
    decimals: number;
    isBusy?: boolean;
    nftRepairJob?: INftJob[];
    nftUpLevelJob?: INftJob[];
}

export interface IAttribute {
    attrName?: string;
    attributesId?: number;
    value?: string;
}

export interface INftJob {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    idNft: number;
    beginAt: string;
    jobTime: number;
    status: number;
}
//----------------------------------------------
// Payload Definition here!
//----------------------------------------------
export interface MarketPlaceSetNftsPayload {
    nfts: INft[];
    total: number;
}
export interface MarketPlaceSetMoreNftsPayload {
    nfts: INft[];
}
export interface MarketPlaceFetchingPayload {
    isFetching: boolean;
}

export interface MarketPlaceSetTypePayload {
    type: TypeEnum;
}

export interface MarketPlaceSetSelectTabPayload {
    tabActive: string;
}

export interface MarketPlaceSetSortPricePayload {
    sortPrice: SortPriceEnum;
}

export interface MarketPlaceSetPagePayload {
    page: number;
}
export interface MarketPlaceSetSneakerTypePayload {
    sneakerType: SneakerTypeEnum;
}
export interface MarketPlaceSetQualityPayload {
    quality: QualityEnum;
}
export interface MarketPlaceSetGemTypePayload {
    gemType: GemTypeEnum;
}

export interface MarketPlaceSetLevelPayload {
    level: {
        start: number;
        end: number;
    };
}

export interface MarketPlaceSetLevelPrevPayload {
    levelPrev: {
        start: number;
        end: number;
    };
}

export interface MarketPlaceSetShoeBoxPayload {
    shoeMint: {
        start: number;
        end: number;
    };
}

export interface MarketPlaceSetShoeBoxPrevPayload {
    shoeMintPrev: {
        start: number;
        end: number;
    };
}
export type FilterEnum = TypeEnum | SortPriceEnum | SneakerTypeEnum | QualityEnum | GemTypeEnum;

//----------------------------------------------
// Action Definition here!
//----------------------------------------------
export interface MarketPlaceFetchingAction extends Action {
    type: MarketPlaceActionType.FETCHING_NFTS;
    payload: MarketPlaceFetchingPayload;
}

export interface MarketPlaceSetNftsAction extends Action {
    type: MarketPlaceActionType.SET_NFTS;
    payload: MarketPlaceSetNftsPayload;
}

export interface MarketPlaceSetMoreNftsAction extends Action {
    type: MarketPlaceActionType.SET_MORE_NFTS;
    payload: MarketPlaceSetMoreNftsPayload;
}

export interface MarketPlaceSetSelectTabAction extends Action {
    type: MarketPlaceActionType.SET_SELECT_TAB;
    payload: MarketPlaceSetSelectTabPayload;
}

export interface MarketPlaceSetTypeAction extends Action {
    type: MarketPlaceActionType.SET_FILTER_TYPE;
    payload: MarketPlaceSetTypePayload;
}

export interface MarketPlaceSetSortPriceAction extends Action {
    type: MarketPlaceActionType.SET_FILTER_SORT_PRICE;
    payload: MarketPlaceSetSortPricePayload;
}

export interface MarketPlaceSetSneakerTypeAction extends Action {
    type: MarketPlaceActionType.SET_FILTER_SNEAKER_TYPE;
    payload: MarketPlaceSetSneakerTypePayload;
}

export interface MarketPlaceClearFilterAction extends Action {
    type: MarketPlaceActionType.CLEAR_FILER;
}

export interface MarketPlaceSetQualityAction extends Action {
    type: MarketPlaceActionType.SET_FILTER_QUALITY;
    payload: MarketPlaceSetQualityPayload;
}
export interface MarketPlaceSetLevelAction extends Action {
    type: MarketPlaceActionType.SET_FILTER_LEVEL;
    payload: MarketPlaceSetLevelPayload;
}

export interface MarketPlaceSetLevelPrevAction extends Action {
    type: MarketPlaceActionType.SET_FILTER_LEVEL_PREV;
    payload: MarketPlaceSetLevelPrevPayload;
}

export interface MarketPlaceSetShoeBoxAction extends Action {
    type: MarketPlaceActionType.SET_FILTER_SHOE_MINT;
    payload: MarketPlaceSetShoeBoxPayload;
}

export interface MarketPlaceSetShoeBoxPrevAction extends Action {
    type: MarketPlaceActionType.SET_FILTER_SHOE_MINT_PREV;
    payload: MarketPlaceSetShoeBoxPrevPayload;
}

export interface MarketPlaceSetGemTypeAction extends Action {
    type: MarketPlaceActionType.SET_FILTER_GEM_TYPE;
    payload: MarketPlaceSetGemTypePayload;
}

export interface MarketPlaceSetPageAction extends Action {
    type: MarketPlaceActionType.SET_CURRENT_PAGE;
    payload: MarketPlaceSetPagePayload;
}
export type MarketPlaceActions =
    | MarketPlaceFetchingAction
    | MarketPlaceSetNftsAction
    | MarketPlaceSetSelectTabAction
    | MarketPlaceSetTypeAction
    | MarketPlaceSetSortPriceAction
    | MarketPlaceSetSneakerTypeAction
    | MarketPlaceClearFilterAction
    | MarketPlaceSetQualityAction
    | MarketPlaceSetLevelAction
    | MarketPlaceSetLevelPrevAction
    | MarketPlaceSetShoeBoxAction
    | MarketPlaceSetShoeBoxPrevAction
    | MarketPlaceSetGemTypeAction
    | MarketPlaceSetPageAction
    | MarketPlaceSetMoreNftsAction;
