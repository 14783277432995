/* eslint-disable no-param-reassign */
import { Separator } from 'src/utils';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

const replaceDecimals = ({ text, autoCorrect = false }: { text: any; autoCorrect?: boolean }) => {
    if (Separator.getDecimalSeparator() === ',' && !text?.includes?.('e+') && !text?.includes?.('e-')) {
        text = text.replace(/\./g, '_');
        text = text.replace(/,/g, '.');
        text = text.replace(/_/g, ',');
    }

    if (autoCorrect) {
        text = text.replace(/,/g, '');
    }

    return text;
};
const toNumber = ({ text, autoCorrect = false }: { text: any; autoCorrect?: boolean }) => {
    const number = replaceDecimals({ text, autoCorrect });
    return _.toNumber(number);
};

interface IHunmanAmount {
    originalAmount?: number;
    decimals: number;
}

const toHumanAmount = (payload: IHunmanAmount) => {
    const { originalAmount = 0, decimals } = payload;
    const amount = new BigNumber(originalAmount);
    if (amount.isNaN()) {
        return 0;
    }
    const indexNumber = new BigNumber(10).pow(decimals);
    return amount.dividedBy(indexNumber).toNumber();
};
export default { toNumber, toHumanAmount };
