import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/marketplace/nft-info/:typeId/:nftId';

const DetailNFTRoute: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/Marketplace/components/DetailNFT')),
    name: 'Detail NFT',
};

export default DetailNFTRoute;
