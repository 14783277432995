import { createSelector } from 'reselect';
import { IRootState } from 'src/redux/interface';

export const authSelector = createSelector(
    (state: IRootState) => state.auth,
    (auth) => auth,
);
export const signinSelector = createSelector(authSelector, (auth) => auth.signin);
export const loadingSelector = createSelector(authSelector, ({ loading }) => loading);
export const accessTokenSelector = createSelector(authSelector, ({ userInfo }) => userInfo.auth?.accessToken);
export const isLoginSelector = createSelector(authSelector, ({ userInfo }) => !!userInfo.auth?.accessToken);
export const profileSelector = createSelector(authSelector, ({ userInfo }) => userInfo.profile);
export const socialSigninSelector = createSelector(authSelector, (auth) => auth.socialSinin);
