import BigNumber from 'bignumber.js';
import { split } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { format } from 'src/utils';
import convert from 'src/utils/convert';

const isSafeInteger = (number: number) => Math.abs(number) <= Number.MAX_SAFE_INTEGER;

const required = (value: any) => (isEmpty(value) ? 'Required' : undefined);

const maxLength = (max: number, message?: string) => (value: string) =>
    value && value.length > max ? message || `Must be ${max} characters or less` : undefined;

const maxDecimal = (max: number, message?: string) => (value: string) => {
    if (value.indexOf(',')) {
        const str = value.split(',')[1];
        if (str?.length > max) return message || `decimal has a maximum of ${max} digits`;
    }
    if (value.indexOf('.')) {
        const str = value.split('.')[1];
        if (str?.length > max) return message || `decimal has a maximum of ${max} digits`;
    }
    return undefined;
};

const minLength = ({ min, message, value }: { min: number; message?: string; value: string }) =>
    value && value.length < min ? message || `Must be ${min} characters or more` : undefined;

const email = ({ message, value }: { message?: string; value?: string }) =>
    required(value) ||
    (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
        ? message || 'Invalid email address'
        : undefined);

const password = ({ value }: { value?: string }) =>
    required(value) || minLength({ min: 6, value: value || '' }) || undefined;

const comparePass = ({ value, compareValue }: { compareValue?: string; value?: string }) =>
    value !== compareValue ? 'Invalid password' : undefined;

const minValue = (min: number, message?: string) => (value: string) =>
    value && convert.toNumber({ text: value }) < min ? message || `Must be at least ${format.number(min)}` : undefined;

const maxValue = (max: number, message?: string) => (value: string) =>
    value && convert.toNumber({ text: value }) > max
        ? message || `Must be less than or equal ${format.number(max)}`
        : undefined;
const number = (value: string) => {
    const bn = new BigNumber(value);
    if (bn.isNaN()) {
        return 'Must be a number';
    }
    if (value && !isSafeInteger(bn.toNumber())) {
        return 'This number is too large!';
    }
    return undefined;
};
const largerThan = (min: number, message?: string) => (value: string) =>
    value && convert.toNumber({ text: value }) <= min
        ? message || `Must be larger than ${format.number(min)}`
        : undefined;

const combinedAmount = [required, number, largerThan(0, 'Please enter an amount greater than 0'), maxDecimal(6)];

const validator = {
    minLength,
    maxLength,
    required,
    email,
    password,
    comparePass,
    maxValue,
    minValue,
    combinedAmount,
};

export default validator;
