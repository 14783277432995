import { get } from 'lodash';
import { batch } from 'react-redux';
import { actionCloseLoading, actionOpenLoading } from 'src/components/Loading/Loading.actions';
import { AppThunkDispatch } from 'src/redux';
import { IRootState } from 'src/redux/interface';
import {
    apiGetBalanceNft,
    apiGetBalanceToken,
    apiGetNftSupport,
    apiGetTokenSupport,
} from 'src/services/account.service';
import { isAccountLoadingSelector } from './Account.selector';
import {
    AccountLoadingAction,
    AccountLoadingPayload,
    AccountNftAction,
    AccountNftPayload,
    AccountSetActiveTabAction,
    AccountSetActiveTabPayload,
    AccountTokenAction,
    AccountTokenPayload,
    AccountTypeAction,
    IBalanceNft,
    IBalanceToken,
} from './Account.types';

export const actionLoadingAccount = (payload: AccountLoadingPayload): AccountLoadingAction => ({
    type: AccountTypeAction.LOADING,
    payload,
});

export const actionSetTokensAccount = (payload: AccountTokenPayload): AccountTokenAction => ({
    type: AccountTypeAction.SET_BALANCE_TOKEN,
    payload,
});
export const actionSetBalanceAccount = (payload: AccountNftPayload): AccountNftAction => ({
    type: AccountTypeAction.SET_BALANCE_NFT,
    payload,
});

export const actionSetActiveTab = (payload: AccountSetActiveTabPayload): AccountSetActiveTabAction => ({
    type: AccountTypeAction.SET_ACTIVE_TAB,
    payload,
});

export const actionGetTokensBalance = () => async (dispatch: AppThunkDispatch, getState: IRootState & any) => {
    const loading = isAccountLoadingSelector(getState());
    if (loading) return;
    try {
        dispatch(actionOpenLoading());
        dispatch(
            actionLoadingAccount({
                loading: true,
            }),
        );
        const tokenSp: any[] = get(await apiGetTokenSupport({}), 'items');
        const tokensBalance: any[] = await apiGetBalanceToken();
        const tokens: IBalanceToken[] = tokenSp.map((token) => {
            const result = tokensBalance.find((e) => e.tokenId.toLowerCase() === token.tokenId.toLowerCase());
            return {
                balance: result.balance,
                tokenId: result.tokenId,
                network: result.network,
                symbol: result.tokenInfo.symbol,
                decimals: result.tokenInfo.decimals,
            };
        });
        // const nfts: IBalanceNft[] = [];
        const nftSp: any[] = get(await apiGetNftSupport({}), 'items');
        const nftsBalance: any[] = get(await apiGetBalanceNft(), 'items');
        const nfts: IBalanceNft[] = nftSp.map((nft) => ({
            type: nft.name,
            tokenId: nft.tokenAddress,
            items: [],
        }));
        nftsBalance.forEach((nft) => {
            const idx = nfts.findIndex((e) => e.type.toLowerCase() === nft.nft.type.toLowerCase());
            nfts[idx].items.push(nft);
        });
        batch(() => {
            dispatch(
                actionSetTokensAccount({
                    tokens,
                }),
            );
            dispatch(
                actionSetBalanceAccount({
                    nfts,
                }),
            );
            dispatch(actionCloseLoading());
        });
    } catch (err) {
        console.log('actionGetTokensBalance err: ', err);
        dispatch(
            actionSetTokensAccount({
                tokens: [],
            }),
        );
    } finally {
        dispatch(
            actionLoadingAccount({
                loading: false,
            }),
        );
        dispatch(actionCloseLoading());
    }
};
