import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { AppThunkDispatch } from 'src/redux';
import { IRootState } from 'src/redux/interface';
import { apiGetProfile } from 'src/services/account.service';
import { apiLogin, apiLoginSocial } from 'src/services/auth.service';
import { loadingSelector, signinSelector, socialSigninSelector } from './Auth.selector';
import {
    AuthActionType,
    AuthFreeAction,
    AuthLoadingAction,
    AuthLoadingPayload,
    AuthLogoutAction,
    AuthSetProfileAction,
    AuthSetProfilePayload,
    AuthSigninAction,
    AuthSigninPayload,
    AuthSigninSocialAction,
    AuthSigninSocialPayload,
    AuthSigninSuccessAction,
    AuthSigninSuccessPayload,
} from './Auth.types';

export const actionSetSignin = (payload: AuthSigninPayload): AuthSigninAction => ({
    type: AuthActionType.SIGNIN,
    payload,
});
export const actionSigninSuccess = (payload: AuthSigninSuccessPayload): AuthSigninSuccessAction => ({
    type: AuthActionType.SIGNIN_SUCCESS,
    payload,
});

export const actionSigninSocialSuccess = (payload: AuthSigninSocialPayload): AuthSigninSocialAction => ({
    type: AuthActionType.SIGNIN_SOCIAL_SUCCESS,
    payload,
});

export const actionLogout = (): AuthLogoutAction => ({
    type: AuthActionType.LOGOUT,
});

export const actionFree = (): AuthFreeAction => ({
    type: AuthActionType.FREE,
});
export const actionSetLoading = (payload: AuthLoadingPayload): AuthLoadingAction => ({
    type: AuthActionType.LOADING,
    payload,
});

export const actionSetProfile = (payload: AuthSetProfilePayload): AuthSetProfileAction => ({
    type: AuthActionType.SET_PROFILE,
    payload,
});

export const actionSigninSocial = () => async (dispatch: AppThunkDispatch, getState: IRootState & any) => {
    try {
        const state = getState();
        const socialResult = socialSigninSelector(state);
        console.log('socialResult: ', socialResult);
        if (!socialResult) {
            dispatch(
                addToast({
                    type: TOAST_TYPES.ERROR,
                    title: 'Failed!',
                    description: 'Social login failed',
                }),
            );
            return;
        }
        dispatch(actionSetLoading({ loading: true }));
        const { token } = await apiLoginSocial({
            socialToken: socialResult.token,
            socialType: socialResult.type as number,
        });
        dispatch(
            actionSigninSuccess({
                email: '',
                accessToken: token,
            }),
        );
        dispatch(actionSetLoading({ loading: false }));
    } catch (error) {
        console.log('actionSigninSocial error: ', error);
        dispatch(actionSetLoading({ loading: false }));
        throw error;
    }
};

export const actionSignin = () => async (dispatch: AppThunkDispatch, getState: IRootState & any) => {
    try {
        const state = getState();
        const { email, password } = signinSelector(state);
        const loading = loadingSelector(state);
        if (!email || !password || loading) return;
        dispatch(actionSetLoading({ loading: true }));
        const { token } = await apiLogin({ email, password });
        dispatch(
            actionSigninSuccess({
                email,
                accessToken: token,
            }),
        );
        dispatch(actionSetSignin({ email: '', password: '' }));
        dispatch(actionSetLoading({ loading: false }));
    } catch (err: any) {
        dispatch(actionSetLoading({ loading: false }));
        console.log('actionSignin error: ', err);
        throw err;
    }
};

export const actionGetProfile = () => async (dispatch: AppThunkDispatch, getState: IRootState & any) => {
    try {
        const profile = await apiGetProfile();
        dispatch(actionSetProfile({ profile }));
    } catch (err) {
        console.log('actionGetProfile error: ', err);
        dispatch(actionFree());
    }
};
