import isEmpty from 'lodash/isEmpty';

declare global {
    interface Window {
        token: string;
        ethereum: any;
        solana: any;
        web3?: Record<string, unknown>;
    }
}

interface IENVS {
    REACT_APP_IS_MAINNET: boolean;
    REACT_APP_VERSION: string;
    REACT_APP_DOMAIN_URL: string;
    REACT_APP_GOOGLE_CLIENT_ID: string;
    REACT_APP_FACEBOOK_APP_ID: string;
    FAST_REFRESH: boolean;
    REACT_APP_BASE_API: string;
    REACT_APP_BLOCK_PASS_CLIENT_ID: string;
    REACT_APP_BLOCK_PASS_API_KEY: string;
    REACT_APP_TELE_CHANNEL: string;
    REACT_APP_TWITTER: string;
    REACT_APP_RETWEET: string;
    REACT_APP_CONSUMER_KEY: string;
    REACT_APP_CONSUMER_SECRET: string;
    REACT_APP_PRIVATE_SALE_ADDRESS: string;
    REACT_APP_MEDIUM: string;
    REACT_APP_DISCORD: string;
    REACT_APP_PROVIDE_URL: string;
}

const defaultEnvs = {
    REACT_APP_IS_MAINNET: false,
    REACT_APP_VERSION: '1.0',
    FAST_REFRESH: true,
    REACT_APP_BASE_API: '',
    REACT_APP_GOOGLE_CLIENT_ID: '',
    REACT_APP_FACEBOOK_APP_ID: '',
    REACT_APP_BLOCK_PASS_CLIENT_ID: '',
    REACT_APP_BLOCK_PASS_API_KEY: '',
    REACT_APP_TELE_CHANNEL: '',
    REACT_APP_TWITTER: '',
    REACT_APP_RETWEET: '',
    REACT_APP_CONSUMER_KEY: '',
    REACT_APP_CONSUMER_SECRET: '',
    REACT_APP_PRIVATE_SALE_ADDRESS: '',
    REACT_APP_MEDIUM: '',
    REACT_APP_DISCORD: '',
    REACT_APP_PROVIDE_URL: '',
};

export const getEnvs = () => {
    let envs: any = {};
    try {
        const PROCCESS_ENV = process.env;
        if (!isEmpty(PROCCESS_ENV)) {
            Object.keys(PROCCESS_ENV).forEach((key: string) => {
                const value = PROCCESS_ENV[key];
                if (value === 'true' || value === 'false') {
                    envs[key] = value === 'true';
                } else {
                    envs[key] = PROCCESS_ENV[key];
                }
                return key;
            });
        }
    } catch (error) {
        console.debug(error);
    } finally {
        envs = isEmpty(envs) ? defaultEnvs : envs;
    }
    return { ...envs, REACT_APP_DOMAIN_URL: window.location.origin };
};

export const ENVS: IENVS = getEnvs();

export const isMainnet: boolean = ENVS.REACT_APP_IS_MAINNET;
export const appDomain: string = ENVS.REACT_APP_DOMAIN_URL;
