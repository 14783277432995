import styled, { ITheme } from 'styled-components';

const Wrapper = styled.div`
    max-width: 500px;
    .ant-drawer,
    ant-drawer-open {
        width: 100%;
        height: 100%;
    }
    .header-drawer {
        border-bottom: 2px solid #2c2c2e;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        .left {
            cursor: pointer;
            width: 50px;
            height: 50px;
            img {
                width: 50px;
                height: 50px;
            }
        }
        .right {
            margin-left: 10px;
            color: white;
        }
    }
    .spending-account {
        margin-top: 20px;
        .header {
            color: #ffffff;
        }
        .box-spending-tokens {
            background: #2c2c2e;
            margin-top: 20px;
            border-radius: 20px;
            padding: 0 15px;
            .spending-token {
                padding: 15px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1.5px solid #616161;

                &.none-border {
                    border: none;
                }
                .token {
                    display: flex;
                    align-items: center;
                    color: white;
                    img {
                        width: 30px;
                    }
                    span {
                        margin-left: 10px;
                    }
                }
                .balance {
                    color: white;
                }
            }
        }
    }
    .go-inventory {
        cursor: pointer;
        margin-top: 20px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #2c2c2e;
        border-radius: 20px;
        width: 100%;
        color: #ffffff;
        .left {
            display: flex;
            align-items: center;
            span {
                margin-left: 10px;
            }
        }
        .right {
            display: flex;
            align-items: center;
        }
    }
`;

export default Wrapper;
